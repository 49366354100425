import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
const logoPositivo = require("../images/logo-positivo.svg")

const ThankYouPage = () => (
  <Layout>
    <SEO title="Gracias por contactarnos" />
    <section className="max-w-5xl px-4 mx-auto mt-32 md:px-0">
      <h1 className="section-heading md:max-w-none">
        ¡Gracias por contactarnos¡
      </h1>
      <div className="max-w-2xl mt-6">
        <p className="text-lg text-gray-800">
          Hemos recibido la información exitosamente. Uno de nuestros asesores
          se pronto se pondrá en contacto contigo.
        </p>
        <p className="mt-8 text-lg">Gracias por confiar en nosotros.</p>
        <div className="mt-1">
          <img
            alt="Gran Colmado logo. Abastecemos confianzaza"
            className="h-12"
            src={logoPositivo}
          />
        </div>
      </div>
    </section>
  </Layout>
)

export default ThankYouPage
